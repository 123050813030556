/* styles */
@use 'settings.scss' as pd;
@import url('https://use.typekit.net/ftx7xsb.css');
@import 'forms.scss';

/* mobile viewport bug fix */
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}
/* because of the mobile viewport bug fix,
   the heights of all elements below must be in % */
:root,
:root body {
  background-color: pd.color(bg);
  color: pd.color(text);
  height: 100%;
  @include pd.font(
    $family: true,
    $line: 1.5,
    $weight: 'regular',
    $size: pd.size(normal, font)
  );
}
#root,
#App {
  height: 100%;
}
.layout {
  position: relative;
  margin: 0;
  display: flex;
  min-height: 100%;
  height: auto;
  font-size: pd.$font_size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .link,
  .inline_link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .inline_link {
    display: inline;
  }
  &.fixed {
    height: 100%;
  }
}
.demoMode {
  .blurInDemo {
    filter: blur(5px);
  }
}

a {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:active {
    color: inherit;
    text-decoration: underline;
  }
  &.underlined,
  .underlined {
    text-decoration: underline;
  }
}

.layout.with_background {
  background-color: pd.color(bg, home);
  color: pd.color(text, home);
  // background-size: 100vw;
  // background-repeat: no-repeat;
  // object-fit: cover;
  // min-width: 100%;
  // min-height: 100%;
}
.layout.white {
  background-color: pd.color(white);
  color: pd.color(text, home);
}
.button {
  cursor: pointer;
}

.centered_dialog {
  justify-content: center;
  align-items: center;
  display: flex;
  object-fit: contain;
  min-height: 100vh;
}

.vertical_text {
  transform: rotate(90deg);
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.bold {
  font-weight: bold;
}
.not_bold {
  font-weight: normal;
}
.italic {
  font-style: italic;
}
.not_italic {
  font-style: normal;
}
.normal {
  @include pd.font(
    $family: true,
    $size: pd.size(normal, font),
    $weight: regular
  );
  @extend .not_bold;
  @extend .not_italic;
}
.margin {
  margin-left: pd.$xxxl;
}
.margin_top {
  margin-top: pd.$xl;
}
.margin_left {
  margin-left: auto;
}
.inline_block {
  display: inline-block;
}
.center {
  text-align: center;
}

/* REDEFINING BOOTSTRAP BUTTONS */
:root {
  .btn {
    @include pd.font(pd.size(btn, font));
    height: 40px;
    padding: 1px 12px;
    min-width: 80px;
    max-width: fit-content;
    border: 1px solid pd.color(border, btn);
    background-color: pd.color(bg, btn);
    color: pd.color(text, btn);
    border-radius: pd.$md;
  }
  .btn.btn-sm,
  .btn.small {
    @include pd.font(pd.size(btn_sm, font));
    height: 30px;
    min-width: unset;
  }
  .btn.hidden {
    visibility: hidden;
    opacity: 0;
  }
  .btn-primary {
    background-color: pd.color(bg, btn-primary);
    color: pd.color(text, btn-primary);
  }
  .btn-outline-primary {
    border-color: pd.color(border, btn-outline-primary);
    background-color: pd.color(bg, btn-outline-primary);
    color: pd.color(text, btn-outline-primary);
  }
  .btn-secondary {
    background-color: pd.color(bg, btn-secondary);
    color: pd.color(text, btn-secondary);
  }
  .btn-outline-secondary {
    border-color: pd.color(border, btn-outline-secondary);
    background-color: pd.color(bg, btn-outline-secondary);
    color: pd.color(text, btn-outline-secondary);
  }
  h1,
  .h1 {
    @include pd.font($family: true, $size: pd.size(h1, font), $bold: true);
  }
  h2,
  .h2 {
    @include pd.font($family: true, $size: pd.size(h2, font), $bold: true);
  }
  h3,
  .h3 {
    @include pd.font($family: true, $size: pd.size(h3, font), $bold: true);
  }
  .label {
    @include pd.font($bold: true);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
.show_not {
  opacity: 0;
}
.show_some {
  opacity: 0.3;
}
.disabled {
  opacity: 0.5;
}
.zeroHeight {
  transition: 1000ms ease height;
  height: 0;
  opacity: 0;
}
.bar {
  background-color: yellow;
  height: 20px;
}
.flex_break {
  flex-basis: 100%;
  height: 0;
}
.tiny {
  @include pd.font($size: pd.size(tiny, font));
}
.small {
  @include pd.font($size: pd.size(small, font));
}
.bottom {
  margin-top: auto;
}
.centre {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  text-align: center;
}
.wrap {
  overflow-wrap: break-word;
  width: 100%;
}
.right {
  margin-left: auto;
}
.rightaligned {
  text-align: right;
}
.oneline {
  display: flex;
  flex-wrap: nowrap;
  gap: pd.$sm;
  align-items: center;
}
.qmark,
.unread_count {
  z-index: 99;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: pd.size(unread, bubble);
  height: pd.size(unread, bubble);
  border: 1px solid pd.color(unread_bg, blocks);
  border-radius: 50%;
  @include pd.font($size: pd.size(small, font));
}
.unread_count {
  margin-left: auto;
  margin-right: auto;
  margin-top: pd.$xl;
  background-color: pd.color(unread_bg, blocks);
  color: pd.color(unread, blocks);
  border: 1px solid pd.color(unread_brdr, blocks);
  @include pd.font($bold: true);
}
.qmark {
  border: 1px solid pd.color(lighterbg);
  width: pd.size(qmark, bubble);
  height: pd.size(qmark, bubble);
  &:hover {
    background-color: pd.color(lighterbg);
    color: white;
    text-decoration: none;
  }
}
.dash_div::after {
  content: '-';
  margin: 0 10px;
}
.debug {
  opacity: 0.5;
  border: 1px solid yellow;
  background-color: pd.color(lighterbg);
  color: white;
  position: absolute;
  top: 48px;
  left: 200px;
  z-index: 9999;
  padding: 0 6px;
  &.green {
    background-color: green;
  }
}
.readmore {
  display: flow-root;
}
.more {
  @include pd.font($italic: true, $size: pd.size(smaller, font));
  color: pd.color(label, chat);
  width: fit-content;
  overflow-wrap: normal;
  float: right;
  display: flex;
  align-items: flex-end;
  height: calc(1.5 * pd.size(normal, font));
}
