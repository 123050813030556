/* Sortable */
@use '../../css/settings.scss' as pd;

.sortable_container {
  // height: auto;
  flex: 1 1 auto;
}
.fixed_list,
.sortable_list {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap, pd.$sm);
  &.pinned_list {
    height: unset;
    margin-bottom: var(--gap, pd.$sm);
  }
}
.sortable {
  position: relative;
  display: flex;
  overflow: visible;
  width: 100%;
  &:not(.pinned) {
    &.noHandle {
      cursor: grab;
    }
    &.dragging {
      // .msg {
      //   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
      // }
      .sortable_handle {
        cursor: move !important;
      }
    }
    // &.dragging > .sortable_handle,
    &:hover > .sortable_handle {
      opacity: 1;
    }
  }
  &.is_new {
    .sortable_handle {
      border-color: pd.color(isnewbdr, chat);
    }
  }
}
.drop-indicator {
  position: absolute;
  z-index: 10;
  background-color: pd.color(indicator, sortable);
  pointer-events: none;
  box-sizing: border-box;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    border: 2px solid pd.color(indicator, sortable);
    border-radius: 50%;
  }
  &.edge-top,
  &.edge-bottom {
    height: 2px;
    left: 4px;
    right: 0;
    &::before {
      top: -3px;
      left: -8px;
    }
  }
  &.edge-top {
    top: calc(-0.5 * (var(--gap, 0px) + 2px));
  }
  &.edge-bottom {
    bottom: calc(-0.5 * (var(--gap, 0px) + 2px));
  }
  &.edge-left,
  &.edge-right {
    width: 2px;
    top: 4px;
    bottom: 0;
    &::before {
      left: -3px;
      top: -8px;
    }
  }
  &.edge-left {
    left: calc(-0.5 * (var(--gap, 0px) + 2px));
  }
  &.edge-right {
    right: calc(-0.5 * (var(--gap, 0px) + 2px));
  }
}
.sortable.dragging {
  opacity: 0.5;
  .popmenu {
    visibility: hidden;
  }
}

.sortable_preview .sortable,
.sortable.dragging {
  .toolhint,
  .popmenu,
  .hide_dragging {
    display: none;
  }
  .colorpicker_btn,
  .toolhint,
  .popmenu {
    pointer-events: none;
    cursor: unset;
  }
}
.sortable_preview .sortable_wrapper {
  width: 100%;
  padding: 0 0 0 0 !important;
  // background-color: black;
  .sortable {
    opacity: 0.8;
    margin: 0 !important;
  }
}

.sortable_list.empty.draggedOver {
  background-color: pd.color(target_bg, sortable);
  border-radius: pd.$sm;
  .sortable:not(.dragging):hover .sortable_handle {
    opacity: 0;
  }
}
.sortable_wrapper {
  display: flex;
  position: relative;
  overflow: visible;
  width: 100%;
}

.sortable_handle {
  cursor: grab;
  position: absolute;
  align-self: flex-start;
  z-index: 1;
  // margin-right: -4px;
  margin-left: -13px;
  opacity: 0;
  background-color: pd.color(bg, chat);
  border: 1px solid transparent;
  border-right: 0;
  border-radius: 8px 0 0 8px;
  width: 14px;
  height: 36px;
  padding: 0;
  &::after {
    @include pd.bgicon(
      'drag2x4',
      $variant: '',
      $position: center,
      $size: 24px,
      $repeat: no-repeat
    );
    background-clip: content-box;
    content: ' ';
    position: absolute;
    padding: 0;
    height: 34px;
    width: 16px;
  }
  &[role='button'] {
    cursor: grab;
  }
}

.sortable_list.horizontal > .sortable_wrapper > .sortable > .sortable_handle {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -18px;
  margin-left: pd.$md;
  margin-right: unset;
  border-radius: 8px 8px 0 0;
  width: 36px;
  height: 18px;
  &::after {
    @include pd.bgicon(
      'drag4x2',
      $variant: '',
      $position: center,
      $size: 24px,
      $repeat: no-repeat
    );
    background-clip: content-box;
    content: ' ';
    position: absolute;
    padding: 0;
    width: 34px;
    height: 18px;
  }
}
