/* Message */
@use '../../css/settings.scss' as pd;

.msg {
  position: relative;
  display: flex;
  flex-direction: column;
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: pd.color(bg, chat);
  border: 1px solid pd.color(bg, chat);
  color: pd.color(text, chat);
  padding: pd.$md pd.$lg pd.$md pd.$xxl;
  margin: pd.$xl pd.$md 0 pd.$xxl;
  border-radius: pd.$md;
  @include pd.font($size: pd.size(msg, font));
  &.msg_mini {
    background-color: pd.color(minibg, chat) !important;
    &.byme {
      background-color: pd.color(minimybg, chat) !important;
      border-color: pd.color(mybg, chat);
    }
    &.is_new {
      border-color: pd.color(isnewbdr, chat);
    }
  }
  &.byme {
    border-color: pd.color(mybg, chat);
    &:hover > .msg_header .dropmenu::before {
      background-color: inherit;
    }
  }
  &.is_new {
    border-color: pd.color(isnewbdr, chat);
    @include pd.font($weight: medium);
  }
  &.byme {
    background-color: pd.color(mybg, chat);
  }
  .msg_wrapper {
    // overflow: hidden; // this breaks the emoji picker
  }
}

.msg_header {
  display: flex;
  justify-content: space-between;
  height: pd.size(small, icons);
}
.msg_color {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  .colorpicker_btn {
    position: absolute;
    z-index: 2;
    bottom: 0;
    top: 0;
    left: 0;
    width: pd.$lg;
    // height: pd.$xxl;
    border-style: solid;
    // border-width: 0 pd.$xxl pd.$xxl 0;
    border-width: 0 0 0 pd.$lg;
    border-top-left-radius: calc(pd.$md - 1px);
    border-bottom-left-radius: calc(pd.$md - 1px);
    background-color: transparent;
  }
  .colorpicker {
    z-index: 400;
    margin-left: pd.$xxl;
  }
}
.msg_menu {
  position: relative;
  margin-left: auto;
  min-width: pd.size(small, icons);
  flex: 0 0 auto;
  .dropmenu_trigger {
    aspect-ratio: unset;
    background-image: unset;
    width: pd.size(small, icons);
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
  }
}

.msg:hover > .msg_header {
  .dropmenu::before {
    position: relative;
    display: block;
    margin-left: auto;
    content: ' ';
    background-color: pd.color(bg, chat);
    filter: blur(5px);
    width: pd.size(small, icons);
    height: pd.size(small, icons);
  }
  .dropmenu_trigger {
    aspect-ratio: 3 / 2;
    background-image: url('../../../public/icons/dropmenu.svg');
    .msg_time {
      display: none;
    }
  }
}
.msg_mini:hover > .msg_header .dropmenu::before {
  background-color: inherit;
}

.msg_time {
  color: pd.color(label, chat);
  text-align: right;
  margin-left: auto;
  white-space: nowrap;
  @include pd.font($size: pd.size(smaller, font));
}

.msg_author {
  color: pd.color(label, chat);
  @include pd.font($size: pd.size(smaller, font), $weight: bold);
  flex: 1 1 auto;
  min-width: 0%; // this ensures that the div will shrink also for large contents
  margin-right: pd.$md;
  cursor: pointer;
  .author_link {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.msg_content {
  width: 100%;
  position: relative;
  overflow-wrap: anywhere;
}
.msg .msg_tail,
.msg_content {
  .form-control {
    position: relative;
    z-index: 10;
    padding: pd.$tiny pd.$md pd.$tiny pd.$md;
    margin: pd.$mtiny pd.$mxl pd.$mtiny pd.$mmd;
    @include pd.font($size: pd.size(msg, font));
    width: calc(100% + pd.$md);
    border: 0;
  }
  .msg_input_btn {
    position: absolute;
    right: -22px;
    bottom: 6px;
    z-index: 10;
    &:nth-last-child(1) {
      right: unset;
      left: -30px;
    }
  }
}

.msg_footer {
  display: flex;
  // position: relative;
}

.msg_edited {
  @include pd.font($size: pd.size(smaller, font), $italic: true);
  color: pd.color(label, chat);
  margin-right: pd.$sm;
}

.msg_replyto {
  display: flex;
  flex-direction: column;
  background-color: pd.color(replybg, chat);
  color: pd.color(text, chat);
  padding: pd.$md pd.$lg;
  margin: 0 0 pd.$lg;
  border-radius: pd.$md;
  border: 1px solid pd.color(label, chat);
}

.msg_date {
  background: pd.color(bg, chat);
  color: pd.color(label, chat);
  padding: pd.$sm pd.$md;
  margin: pd.$xl auto 0;
  border-radius: pd.$md;
  &.first {
    margin-top: pd.$sm;
  }
}
.msg_unread {
  position: relative;
  display: flex;
  border-bottom: 1px solid pd.color(isnewbdr, chat);
  text-align: center;
  margin: pd.$lg 0;
  @include pd.font($italic: true);
  > div {
    margin: auto;
    margin-bottom: pd.$mlg;
    padding: 0 pd.$sm;
    background-color: pd.color(bg, blocks);
  }
}

.msg a:any-link {
  color: pd.color(link, chat);
  text-decoration-line: underline;
  overflow-wrap: anywhere;
  &:hover {
    color: pd.color(hoverlink, chat);
    text-decoration-color: pd.color(link, chat);
  }
}

.msg .msg_file {
  // max-height: 300px;
  min-height: 100px;
  margin: 0 -12px 0 -20px;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  img {
    max-width: 100%;
  }
}

.img_view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}
