/* StaticPage */
@use '../../css/settings.scss' as pd;

.layout {
  .static_page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 70px;
    .page_container {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      .page_content {
        // max-width: max(80vw, 1100px);
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: pd.color(white);
        overflow: auto;
        padding: 0 80px 20px 80px;
        @media screen and (max-width: 950px) {
          padding: 0 20px 20px 20px;
        }
        @media screen and (max-device-width: 768px) {
          padding: 0 20px 20px 20px;
        }
      }
    }
    section {
      position: relative;
      &:not(:last-child) {
        margin: pd.$xxl 0;
      }
      &.static_mobile_info {
        display: none;
        background-color: pd.color(bg1, notify);
        color: pd.color(text1, notify);
        text-align: center;
        padding: pd.$md;
        border: 1px solid pd.color(border);
        border-radius: pd.$md;
        @media screen and ((max-device-width: 768px) or (max-device-height: 480px)) {
          display: block;
        }
      }
      &:first-child {
        margin-top: pd.$xxxl;
      }
      &:last-child {
        margin-bottom: pd.$xxxl;
      }
      h1,
      h2,
      h3 {
        @include pd.font($bold: true, $condensed: true);
        padding: 0;
      }
      h1 {
        color: pd.color(accent);
      }
      h1 {
        font-size: 60px;
        line-height: 66px;
      }
      h2 {
        font-size: 40px;
        line-height: 50px;
      }
      h3 {
        font-size: 25px;
        line-height: 35px;
      }
      p,
      div {
        font-size: 18px;
        line-height: 26px;
      }
      a {
        text-decoration: underline;
      }
      &.static_top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include pd.font($condensed: true);
        p,
        div {
          font-size: 25px;
          line-height: 35px;
        }
        > div {
          margin-bottom: pd.$xl;
        }
        @media screen and (max-width: 950px) {
          flex-direction: column;
        }
      }
      .static_media_wrapper {
        display: flex;
        gap: pd.$xl;
        @media screen and (max-width: 950px) {
          flex-direction: column;
        }
        video {
          cursor: pointer;
        }
      }
      .static_media {
        width: min(50vw, 850px);
        aspect-ratio: 1920 / 1064;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        border-radius: pd.$md;
        margin-left: auto;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @media screen and (max-width: 950px) {
          margin: pd.$lg 0;
          width: 100%;
        }
        div {
          text-align: center;
          background-color: pd.color(accent);
          color: pd.color(white);
          margin: auto;
          padding: 0 pd.$md;
          border-radius: pd.$md;
          border: 2px solid pd.color(white);
        }
      }
      .static_link {
        position: relative;
        width: fit-content;
        white-space: nowrap;
        background-color: pd.color(accent);
        color: pd.color(white);
        border: 2px solid pd.color(accent);
        border-radius: 100vw;
        padding: 0.1vw 1vw 0.3vw;
        @include pd.font($size: 90%, $weight: medium);
        &.inverted {
          background-color: pd.color(white);
          color: pd.color(text, home);
          &:hover {
            background-color: pd.color(accent);
            color: pd.color(white);
          }
        }
        &:hover {
          background-color: pd.color(white);
          color: pd.color(text, home);
          text-decoration: none;
        }
        a {
          text-decoration: none;
        }
      }
      .static_arrowbullets {
        padding: 0 0.6vw;
        margin-left: 2.4vw;
        li {
          list-style: none;
          position: relative;
          &::before {
            background-image: url('../../../public/icons/arrow-accent.svg');
            background-size: 1.8vw;
            background-repeat: no-repeat;
            background-position: center;
            content: ' ';
            width: 1.8vw;
            height: 1.8vw;
            display: block;
            position: absolute;
            top: 0.3vw;
            left: -2.4vw;
          }
        }
      }
      .static_img div {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 0;
        margin-top: pd.$xxxl;
        padding-bottom: 600px;
      }
      &.static_cards {
        margin: pd.$xxxl 0;
        display: flex;
        justify-items: stretch;
        align-items: flex-start;
        gap: pd.$xxl;
        .card_wrapper {
          flex: 1 1 25%;
          display: flex;
          flex-direction: column;
        }
        .static_link {
          margin: pd.$xl auto pd.$xl;
          min-width: fit-content;
          max-width: 80%;
          text-align: center;
          + .card_img {
            margin-top: unset;
          }
        }
        .card {
          padding: pd.$lg pd.$xxl;
          flex: 1 1 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          .static_link {
            margin-top: 0;
          }
          .card_img {
            margin-top: auto;
            overflow: hidden;
            div {
              aspect-ratio: 1 / 1;
              margin: -10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
        .card_1 {
          margin-top: 12px;
        }
        .card_2 {
          margin-top: -12px;
        }
        .card_3 {
          margin-top: 24px;
        }
        .card_4 {
          margin-top: 0;
        }
        @media screen and (max-width: 950px) {
          flex-wrap: wrap;
          .card_wrapper {
            flex: 1 1 40%;
          }
        }
        @media screen and (max-width: 500px) {
          flex-wrap: wrap;
          flex-direction: column;
          .card_wrapper {
            flex: 1 1 40%;
            margin-top: 0;
          }
        }
      }
    }
  }
}

.help_page {
  section > *:not(.help_img, h1, h2) {
    max-width: 50%;
  }
}
.help_img {
  width: 50%;
  margin-top: -12px;
  margin-left: 12px;
  float: right;
  display: flex;
  position: relative;
  img {
    max-width: 100%;
    &.img_block {
      height: 560px;
    }
  }
}

.consent_img {
  width: 200px;
  float: right;
  img {
    display: block;
    margin: auto;
    float: right;
    max-height: 240px;
  }
}
